var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getPermission('property:create') || _vm.getPermission('property:update'))?_c('v-layout',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":12,"sm":12}},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"position":"static"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-layout',{staticClass:"d-block"},[_c('v-row',[_c('v-col',{staticClass:"iv-custom-field pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"md":"12","sm":"12"}},[_c('v-row',[(!_vm.raw && !_vm.property)?_c('v-col',{staticClass:"pt-0",attrs:{"md":"12","sm":"12"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","value":1,"color":"cyan","label":"Service address is same as Site Address"},on:{"change":function($event){return _vm.isSameProperty()}},model:{value:(_vm.propertyAddress.is_same),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "is_same", $$v)},expression:"propertyAddress.is_same"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","label":"Get your current location","color":"cyan"},on:{"change":_vm.getUserCurrentAddress},model:{value:(_vm.trackGPS),callback:function ($$v) {_vm.trackGPS=$$v},expression:"trackGPS"}})],1),_c('v-col',{attrs:{"md":"6","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"custom-google-autocomplete"},[_c('v-text-field',{attrs:{"id":"google-address-map","append-icon":"search","dense":"","filled":"","label":"Search Address","solo":"","flat":"","color":"cyan","loading":_vm.autocompleteLoading,"disabled":_vm.propertyAddress.is_same},on:{"keyup":_vm.getGoogleAddress,"blur":function($event){_vm.isFocused = false},"focus":function($event){_vm.isFocused = true}},model:{value:(_vm.searchAddress),callback:function ($$v) {_vm.searchAddress=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchAddress"}}),(
                              (hover || _vm.isFocused) && _vm.autocompleteList.length
                            )?_c('v-list',{staticClass:"custom-google-autocomplete-list",attrs:{"two-line":"","elevation":"4"}},_vm._l((_vm.autocompleteList),function(address,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.selectGoogleAddress(index)}}},[_c('v-list-item-icon',{staticClass:"m-0 mr-3 my-auto"},[_c('v-icon',{attrs:{"color":"cyan"}},[_vm._v(" mdi-map-marker ")])],1),(
                                  _vm.lodash.isEmpty(
                                    address.structured_formatting
                                  ) === false
                                )?_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.structured_formatting.main_text))]),_c('v-list-item-subtitle',{staticClass:"font-size-12"},[_vm._v(_vm._s(address.structured_formatting.secondary_text))])],1):_c('v-list-item-content',{staticClass:"py-1"},[_c('v-list-item-title',{staticClass:"font-size-14"},[_vm._v(_vm._s(address.description))])],1)],1)}),1):_vm._e()],1)]}}],null,false,3559567938)})],1)],1)],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Unit No.")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.propertyAddress.unit_no,
                        'Unit No.',
                        100
                      ) ],"dense":"","filled":"","label":"Unit No.","solo":"","flat":"","color":"cyan","disabled":_vm.propertyAddress.is_same},model:{value:(_vm.propertyAddress.unit_no),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "unit_no", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"propertyAddress.unit_no"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Address Line 1")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.required(
                        _vm.propertyAddress.street_1,
                        'Address Line 1'
                      ),
                      _vm.validateRules.maxLength(
                        _vm.propertyAddress.street_1,
                        'Address Line 1',
                        255
                      ) ],"dense":"","filled":"","label":"Address Line 1","solo":"","flat":"","color":"cyan","disabled":_vm.propertyAddress.is_same},model:{value:(_vm.propertyAddress.street_1),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "street_1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"propertyAddress.street_1"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Address Line 2")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.propertyAddress.street_2,
                        'Address Line 2',
                        255
                      ) ],"dense":"","filled":"","label":"Address Line 2","solo":"","flat":"","color":"cyan","disabled":_vm.propertyAddress.is_same},model:{value:(_vm.propertyAddress.street_2),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "street_2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"propertyAddress.street_2"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Postal Code")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"rules":[
                      _vm.validateRules.required(
                        _vm.propertyAddress.zip_code,
                        'Postal Code'
                      ) ],"dense":"","filled":"","label":"Postal Code","solo":"","flat":"","color":"cyan","disabled":_vm.propertyAddress.is_same},model:{value:(_vm.propertyAddress.zip_code),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "zip_code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"propertyAddress.zip_code"}})],1),_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Country")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.required(
                        _vm.propertyAddress.country,
                        'Country'
                      ),
                      _vm.validateRules.maxLength(
                        _vm.propertyAddress.country,
                        'Country',
                        10
                      ) ],"dense":"","filled":"","label":"Country","solo":"","flat":"","color":"cyan","disabled":_vm.propertyAddress.is_same},model:{value:(_vm.propertyAddress.country),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "country", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"propertyAddress.country"}})],1),_c('v-col',{staticClass:"d-none",attrs:{"md":"4","sm":"12"}},[_c('label',[_vm._v("Latitude")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.propertyAddress.latitude,
                        'Latitude',
                        100
                      ) ],"dense":"","filled":"","label":"Latitude","solo":"","flat":"","color":"cyan"},model:{value:(_vm.propertyAddress.latitude),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "latitude", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"propertyAddress.latitude"}})],1),_c('v-col',{staticClass:"d-none",attrs:{"md":"4","sm":"12"}},[_c('label',[_vm._v("Longitude")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.propertyAddress.longitude,
                        'Longitude',
                        100
                      ) ],"dense":"","filled":"","label":"Longitude","solo":"","flat":"","color":"cyan"},model:{value:(_vm.propertyAddress.longitude),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "longitude", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"propertyAddress.longitude"}})],1),_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Remarks")]),_c('v-textarea',{attrs:{"rules":[
                      _vm.validateRules.maxLength(
                        _vm.propertyAddress.remarks,
                        'Remarks',
                        512
                      ) ],"auto-grow":"","dense":"","filled":"","label":"Remarks","solo":"","flat":"","row-height":"20","color":"cyan"},model:{value:(_vm.propertyAddress.remarks),callback:function ($$v) {_vm.$set(_vm.propertyAddress, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"propertyAddress.remarks"}})],1),_c('v-col',{attrs:{"md":"12","sm":"12"}},[(_vm.getPermission('attachment:create'))?_c('div',[_c('label',{staticClass:"custom-form-label width-100"},[_vm._v("Images "),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2"},'v-icon',attrs,false),on),[_vm._v("mdi-progress-question")])]}}],null,false,2647039722)},[_c('span',[_vm._v("More than 5 images are not allowed")])])],1),_c('div',[_c('AttachmentWithName',{attrs:{"label":true,"attachments":_vm.attachment,"allowUpload":"","onlyImage":true},on:{"file:updated":_vm.updateAttachment,"update:attachments":function($event){_vm.attachment=$event}}})],1)]):_vm._e()])],1)],1)],1)],1)],1)],1)],1),(false && !_vm.isDialog)?[_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }