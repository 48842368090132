<template>
  <v-layout>
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <template v-if="isDialog">
        <v-row v-for="(row, index) in contactPersons" :key="index">
          <v-col md="12" class="py-0">
            <p class="middle-header-background px-4 mb-0 font-size-20">
              Basic Details
            </p>
          </v-col>
          <v-col md="12">
            <table width="100%" style="table-layout: fixed">
              <tr>
                <td width="20%" class="px-2">
                  <label
                    for="first-name"
                    class="my-0 font-size-16 font-weight-500 required"
                    >First Name</label
                  >
                </td>
                <td width="30%" class="px-2">
                  <v-text-field
                    id="t-first_name"
                    v-model.trim="row.first_name"
                    :rules="[
                      validateRules.required(row.first_name, 'first name'),
                      validateRules.minLength(row.first_name, 'first name', 2),
                      validateRules.maxLength(
                        row.first_name,
                        'first name',
                        100
                      ),
                    ]"
                    dense
                    filled
                    label="First Name"
                    solo
                    flat
                    color="cyan"
                    class="required-field"
                    v-on:keyup="getAllname(index)"
                    hide-details
                  ></v-text-field>
                </td>
                <td width="20%" class="px-2">
                  <label
                    for="phone-number"
                    class="my-0 font-size-16 font-weight-500 required"
                    >Phone Number</label
                  >
                </td>
                <td width="30%" class="px-2">
                  <PhoneTextField
                    id="t-primary_phone"
                    v-model="row.primary_phone"
                    :value="row.primary_phone"
                    required
                    hide-details
                  >
                  </PhoneTextField>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col md="12" class="py-0 mt-4">
            <p class="middle-header-background px-4 mb-0 font-size-20">
              Other Details
            </p>
          </v-col>
          <v-col md="12">
            <table width="100%" style="table-layout: fixed">
              <tr>
                <td width="20%" class="px-2 py-2">
                  <label
                    for="last-name"
                    class="my-0 font-size-16 font-weight-500"
                    >Last Name</label
                  >
                </td>
                <td width="30%" class="px-2 py-2">
                  <v-text-field
                    id="t-last_name"
                    v-model.trim="row.last_name"
                    :rules="[
                      validateRules.minLength(row.last_name, 'last name', 2),
                      validateRules.maxLength(row.last_name, 'last name', 100),
                    ]"
                    dense
                    filled
                    label="Last Name"
                    solo
                    flat
                    color="cyan"
                    v-on:keyup="getAllname(index)"
                    hide-details
                  ></v-text-field>
                </td>
                <td width="20%" class="px-2 py-2">
                  <label
                    for="display-name"
                    class="my-0 font-size-16 font-weight-500"
                    >Display Name</label
                  >
                </td>
                <td width="30%" class="px-2 py-2">
                  <v-text-field
                    id="t-display_name"
                    v-model.trim="row.display_name"
                    :rules="[
                      validateRules.minLength(
                        row.display_name,
                        'display name',
                        2
                      ),
                      validateRules.maxLength(
                        row.display_name,
                        'display name',
                        100
                      ),
                    ]"
                    dense
                    filled
                    label="Display Name"
                    solo
                    flat
                    color="cyan"
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td width="20%" class="px-2 py-2">
                  <label for="email" class="my-0 font-size-16 font-weight-500"
                    >Email</label
                  >
                </td>
                <td width="30%" class="px-2 py-2">
                  <v-text-field
                    id="t-primary_email"
                    v-model.trim="row.primary_email"
                    :rules="[
                      validateRules.validEmail(row.primary_email, 'email'),
                      validateRules.minLength(row.primary_email, 'email', 2),
                      validateRules.maxLength(row.primary_email, 'email', 100),
                    ]"
                    dense
                    filled
                    label="Email"
                    solo
                    flat
                    color="cyan"
                    hide-details
                  ></v-text-field>
                </td>
                <td width="20%" class="px-2 py-2"></td>
                <td width="30%" class="px-2 py-2"></td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="position: static"
        >
          <v-layout class="d-block">
            <v-row>
              <v-col class="iv-custom-field pt-0" cols="12" md="12" sm="12">
                <v-container fluid class="pt-0">
                  <v-row>
                    <v-col style="min-height: 45px" class="text-right pt-0">
                      <v-btn
                        v-if="allow_create"
                        class="my-2 custom-bold-button white--text"
                        v-on:click="dialog = true"
                        color="cyan"
                        small
                      >
                        Add Contact Person
                      </v-btn>
                    </v-col>
                  </v-row>
                  <table width="100%">
                    <thead>
                      <tr>
                        <th class="simple-table-th">#</th>
                        <th class="simple-table-th">Salutation</th>
                        <th class="simple-table-th">First Name</th>
                        <th class="simple-table-th">Last Name</th>
                        <th class="simple-table-th">Display Name</th>
                        <th class="simple-table-th">Phone Number</th>
                        <th class="simple-table-th">Email</th>
                        <th class="simple-table-th"></th>
                      </tr>
                    </thead>
                    <tbody v-if="contactPersons.length">
                      <tr v-for="(row, index) in contactPersons" :key="index">
                        <td class="simple-table-td">{{ index + 1 }}</td>
                        <td class="simple-table-td text-capitalize">
                          {{ row.title }}.
                        </td>
                        <td class="simple-table-td">{{ row.first_name }}</td>
                        <td class="simple-table-td">{{ row.last_name }}</td>
                        <td class="simple-table-td">{{ row.display_name }}</td>
                        <td class="simple-table-td">{{ row.primary_phone }}</td>
                        <td class="simple-table-td">{{ row.primary_email }}</td>
                        <td class="simple-table-td">
                          <v-menu
                            top
                            v-model="row.menu"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-2"
                                color="red lighten-1"
                                dark
                                fab
                                x-small
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon small dark>
                                  mdi-trash-can-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <DeleteSmallConfirm
                              :message="`Are you sure, you want to delete ${row.display_name} ?`"
                              v-on:cancel="row.menu = false"
                              v-on:success="removeContactPerson(index)"
                            ></DeleteSmallConfirm>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-else>
                      <tr>
                        <td colspan="8">
                          <p
                            class="my-4 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image"
                            />
                            Uhh... There are no contact person at the moment.
                          </p>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </v-container>
              </v-col>
            </v-row>
          </v-layout>
        </perfect-scrollbar>
        <Dialog :commonDialog.sync="dialog" :dialog-width="1024">
          <template v-slot:title> Add Contact Person </template>
          <template v-slot:body>
            <v-form
              ref="personForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="appendContactPerson"
            >
              <v-row>
                <v-col md="12" class="py-0">
                  <p class="middle-header-background px-4 mb-0 font-size-20">
                    Basic Details
                  </p>
                </v-col>
                <v-col md="12">
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td width="20%" class="px-2">
                        <label
                          for="first-name"
                          class="my-0 font-size-16 font-weight-500 required"
                          >First Name</label
                        >
                      </td>
                      <td width="30%" class="px-2">
                        <v-text-field
                          id="y-first_name"
                          v-model.trim="contactPerson.first_name"
                          :rules="[
                            validateRules.required(
                              contactPerson.first_name,
                              'first name'
                            ),
                            validateRules.minLength(
                              contactPerson.first_name,
                              'first name',
                              2
                            ),
                            validateRules.maxLength(
                              contactPerson.first_name,
                              'first name',
                              100
                            ),
                          ]"
                          dense
                          filled
                          label="First Name"
                          solo
                          flat
                          color="cyan"
                          class="required-field"
                          hide-details
                        ></v-text-field>
                      </td>
                      <td width="20%" class="px-2">
                        <label
                          for="phone-number"
                          class="my-0 font-size-16 font-weight-500 required"
                          >Phone Number</label
                        >
                      </td>
                      <td width="30%" class="px-2">
                        <PhoneTextField
                          id="y-primary_phone"
                          v-model="contactPerson.primary_phone"
                          :value="contactPerson.primary_phone"
                          required
                          hide-details
                        >
                        </PhoneTextField>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col md="12" class="py-0 mt-4">
                  <p class="middle-header-background px-4 mb-0 font-size-20">
                    Other Details
                  </p>
                </v-col>
                <v-col md="12">
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td width="20%" class="px-2 py-2">
                        <label
                          for="first-name"
                          class="my-0 font-size-16 font-weight-500"
                          >Last Name</label
                        >
                      </td>
                      <td width="30%" class="px-2 py-2">
                        <v-text-field
                          id="y-last_name"
                          v-model.trim="contactPerson.last_name"
                          :rules="[
                            validateRules.minLength(
                              contactPerson.last_name,
                              'last name',
                              2
                            ),
                            validateRules.maxLength(
                              contactPerson.last_name,
                              'last name',
                              100
                            ),
                          ]"
                          dense
                          filled
                          label="Last Name"
                          solo
                          flat
                          color="cyan"
                          hide-details
                        ></v-text-field>
                      </td>
                      <td width="20%" class="px-2 py-2">
                        <label
                          for="first-name"
                          class="my-0 font-size-16 font-weight-500"
                          >Display Name</label
                        >
                      </td>
                      <td width="30%" class="px-2 py-2">
                        <v-text-field
                          id="y-display_name"
                          v-model.trim="contactPerson.display_name"
                          :rules="[
                            validateRules.minLength(
                              contactPerson.display_name,
                              'display name',
                              2
                            ),
                            validateRules.maxLength(
                              contactPerson.display_name,
                              'display name',
                              100
                            ),
                          ]"
                          dense
                          filled
                          label="Display Name"
                          solo
                          flat
                          color="cyan"
                          hide-details
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%" class="px-2 py-2">
                        <label
                          for="first-name"
                          class="my-0 font-size-16 font-weight-500"
                          >Email</label
                        >
                      </td>
                      <td width="30%" class="px-2 py-2">
                        <v-text-field
                          id="y-primary_email"
                          v-model.trim="contactPerson.primary_email"
                          :rules="[
                            validateRules.validEmail(
                              contactPerson.primary_email,
                              'email'
                            ),
                            validateRules.minLength(
                              contactPerson.primary_email,
                              'email',
                              2
                            ),
                            validateRules.maxLength(
                              contactPerson.primary_email,
                              'email',
                              100
                            ),
                          ]"
                          dense
                          filled
                          label="Email"
                          solo
                          flat
                          color="cyan"
                          hide-details
                        ></v-text-field>
                      </td>
                      <td width="20%" class="px-2 py-2"></td>
                      <td width="30%" class="px-2 py-2"></td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-slot:action>
            <v-btn
              v-on:click="dialog = false"
              class="mx-2 custom-grey-border custom-bold-button"
            >
              Cancel
            </v-btn>
            <v-btn
              class="mx-2 custom-bold-button white--text"
              v-on:click="appendContactPerson"
              color="cyan"
            >
              Add
            </v-btn>
          </template>
        </Dialog>
      </template>
    </v-col>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import DeleteSmallConfirm from "@/view/pages/partials/DeleteSmallConfirm";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { currentUser } from "@/core/services/jwt.service.js";
import ObjectPath from "object-path";

export default {
  name: "contact-persons",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin],
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    person: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
      super_admin: 0,
      id_executive: 0,
      contactPerson: {
        id: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0,
      },
      contactPersons: [],
    };
  },
  watch: {
    "contactPerson.first_name"() {
      this.contactPerson.display_name = `${
        this.contactPerson.first_name ? this.contactPerson.first_name : ""
      } ${this.contactPerson.last_name ? this.contactPerson.last_name : ""}`;
    },
    "contactPerson.last_name"() {
      this.contactPerson.display_name = `${
        this.contactPerson.first_name ? this.contactPerson.first_name : ""
      } ${this.contactPerson.last_name ? this.contactPerson.last_name : ""}`;
    },
    contactPersons: {
      deep: true,
      immediate: true,
      handler(param) {
        let result = this.lodash.cloneDeep(param);
        this.$emit("saveContactPerson", result);
      },
    },
    detail: {
      deep: true,
      handler() {
        this.makeDefaultEntry();
      },
    },
  },
  methods: {
    getAllname(index) {
      this.contactPersons[index].display_name =
        (this.contactPersons[index].first_name
          ? this.contactPersons[index].first_name
          : "") +
        (this.contactPersons[index].last_name
          ? " " + this.contactPersons[index].last_name
          : "");
    },
    appendContactPerson() {
      if (!this.$refs.personForm.validate()) {
        return false;
      }
      this.contactPersons.push({
        menu: false,
        id: this.contactPerson.id,
        title: this.contactPerson.title,
        first_name: this.contactPerson.first_name,
        last_name: this.contactPerson.last_name,
        display_name: this.contactPerson.display_name,
        primary_email: this.contactPerson.primary_email,
        primary_phone: this.contactPerson.primary_phone,
        will_notified: this.contactPerson.will_notified,
        position: this.contactPerson.position,
        default: this.contactPerson.default,
      });
      this.contactPerson = {
        id: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0,
      };
      this.dialog = false;
    },
    makeDefaultEntry() {
      const _this = this;
      try {
        let emailData = _this.lodash.filter(
          _this.detail.details,
          function (data) {
            return data.type === 1;
          }
        );
        /*let contactData = _this.lodash.filter(
          _this.detail.details,
          function (data) {
            return data.type === 2;
          }
        );*/
        if (_this.detail.first_name) {
          let firstEntry = {
            id: null,
            menu: false,
            title: _this.lodash.toString(_this.detail.salutation),
            first_name: _this.lodash.toString(_this.detail.first_name),
            last_name: _this.lodash.toString(_this.detail.last_name),
            display_name: _this.lodash.toString(_this.createDisplayName()),
            primary_email: _this.lodash.head(emailData)
              ? _this.lodash.toString(_this.lodash.head(emailData).value)
              : null,
            primary_phone: _this.lodash.toString(_this.detail.phone_number),
            default: 1,
            will_notified: 1,
            position: null,
          };
          _this.contactPersons.splice(0, 1, firstEntry);
        }
      } catch (error) {
        _this.contactPersons.splice(0, 1);
        _this.logError(error);
      }
    },
    createDisplayName() {
      let firstname = "";
      if (this.detail.first_name) {
        firstname = this.detail.first_name.toLowerCase();
      }
      let lastname = "";
      if (this.detail.last_name) {
        lastname = this.detail.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    pushContactPerson() {
      this.contactPersons.push({
        id: null,
        title: "mr",
        menu: false,
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0,
      });
    },
    removeContactPerson(index) {
      this.contactPersons.splice(index, 1);
    },
  },
  components: {
    Dialog,
    DeleteSmallConfirm,
    PhoneTextField,
  },
  mounted() {
    this.loggin_user = currentUser();

    this.super_admin = ObjectPath.get(this.loggin_user, "super_admin");
    this.id_executive = ObjectPath.get(this.loggin_user, "id_executive");

    if (this.isDialog) {
      if (this.contactPersons.length <= 0) {
        this.contactPersons.push({
          id: null,
          title: "mr",
          menu: false,
          first_name: null,
          last_name: null,
          display_name: null,
          primary_email: null,
          primary_phone: null,
          will_notified: 1,
          position: null,
          default: 0,
        });
      }
    }
    this.makeDefaultEntry();
    if (this.person > 0) {
      this.contactPersons = [this.getPersonById(this.customer, this.person)];
    }
  },
  computed: {
    allow_create() {
      if (this.id_executive == 1) {
        return !(this.contactPersons && this.contactPersons.length > 0);
      }
      return true;
    },
  },
};
</script>
